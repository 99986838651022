<!-- eslint-disable vue/valid-v-model -->
<template>
  <fragment>
    <tr>
      <td
        style="margin-left: 25px;
                                margin-right: 25px;
                                min-width: 250px !important;
                                text-align: center;"
      >
        <!--:src="`${url_public}voucher/logo.png`"-->
        <img
          style="max-width: 125px;max-height: 125px;margin-bottom: 30px;"
          :src="`${url_public}config/logopdf.png`"
        />
        <br />
        <b>{{ $t('lbl.emition') }}:</b> {{ $moment(item.date_compra).format('D-MMM-YYYY') }}<br />
        <b>Voucher:</b> {{ item.number_vouchers[0].voucher }}<br />
        <b>{{ $t('lbl.reserva') }}:</b> {{ item.code }}<br />
        <br />
        <br />
        <br />
        <b>{{ $t('lbl.claveConfirm') }}:</b><br />
        <fragment v-if="item.number_confirmed">
          {{ item.number_confirmed }}
        </fragment>
        <br /><br /><br />
      </td>
      <td
        width="50%"
        style="min-width: 350px !important;"
        :style="
          `border-left-style: dashed;
                            border-left-width: 4px;
                            border-left-color: ${$vuetify.theme.themes.light.primary};
                            padding-left: 15px`
        "
      >
        <fragment v-if="item.entity === 'cars'">
          <b>{{ $t('lbl.car') }}:</b>
          <span
            style="margin-left: 53px;"
          >{{ item.data_service.items.car.name }}
            <span> o similar </span>
            <span v-if="item.data_service.items.category_id">({{ item.data_service.items.category_id.name }})</span>
          </span>
          <br />
          <b>{{ $t('menu.operador') }}:</b>
          <span style="margin-left: 14px;">{{ item.data_service.items.rentadora.name }}</span>
          <br />
          <b>{{ $t('lbl.recogida') }}:</b>
          <span style="margin-left: 19px;">{{ item.data_service.recogida.name }}</span>
          <br />
          <span style="margin-left: 95px;">{{
            $moment(item.data_service.dateRecogida).format('D-MMM-YYYY, H:mm')
          }}</span>
          <br />
          <b>{{ $t('lbl.entrega') }}:</b>
          <span style="margin-left: 27px;">{{ item.data_service.entrega.name }}</span>
          <br />
          <span style="margin-left: 95px;">{{
            $moment(item.data_service.dateEntrega).format('D-MMM-YYYY, H:mm')
          }}</span>
          <br />
          <b>{{ $t('lbl.incluye') }}:</b>
          <span style="margin-left: 31px;">
            <span
              v-for="(suplement, indS) in item.data_service.items.suplementos"
              :key="indS"
              :style="indS > 0 ? 'margin-left: 97px;' : ''"
            >
              {{ suplement.suplemento.name
              }}<span v-if="indS < item.data_service.items.suplementos.length - 1">
                <br />
              </span>
            </span>
          </span>
          <br />
          <b>{{ $t('lbl.clients') }}:</b>
          <span style="margin-left: 27px;">
            {{ item.data_client.nameConductorPrincipal }}
            {{ item.data_client.nameSecoundConductorPrincipal }}
            {{ item.data_client.apellidosConductorPrincipal }}
          </span>
          <br />
        </fragment>
        <fragment v-else-if="item.entity === 'hotels'">
          {{ $t('landing.secFour.prod6') }}
        </fragment>
        <fragment v-else-if="item.entity === 'vuelos' || item.entity === 'flights'">
          {{ $t('landing.secFour.prod1') }}
        </fragment>
      </td>
      <td style="text-align: right;">
        <img
          style="max-width: 540;max-height: 350px;margin-bottom: 25px;"
          max-height="150"
          max-width="540"
          :src="`${url_public}voucher/cunno.jpg`"
        />
      </td>
    </tr>
    <tr>
      <td
        colspan="3"
        :style="
          `border-top-style: dashed;
                            border-top-width: 4px;
                            border-top-color: ${$vuetify.theme.themes.light.primary};
                            padding: 15px 50px 25px 15px;`
        "
      >
        <b>{{ $t('lbl.atension') }}</b>
        <ul>
          <li>{{ $t('lbl.atension1') }}</li>
          <li v-if="item.entity !== 'cars'">
            {{ $t('lbl.atension2') }}
          </li>
          <li v-if="item.entity !== 'cars'">
            {{ $t('lbl.atension3') }}
          </li>
        </ul>
      </td>
    </tr>
  </fragment>
</template>
<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    item: {
      type: Object,
    },
  },
  data() {
    return {
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
    }
  },
}
</script>
