<template>
  <div
    v-if="!isLoading"
    id="misc"
  >
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <v-img
      class="misc-tree"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <div class="page-title text-center px-5">
      <!--<h2 class="text-2xl font-weight-semibold text--primary">
        {{ config.name }}!!!
      </h2>-->
      <v-row>
        <v-col
          cols="12"
          md="6"
          offset-md="3"
        >
          <p class="text-sm text-left">
            <b>{{ $t('lbl.createReserve') }}</b>: {{ item.code }}
          </p>
          <p class="text-sm text-left">
            <b>{{ $t('lbl.createCotization2') }}</b>:
            <fragment v-if="item.entity === 'cars'">
              {{ $t('landing.secFour.prod2') }}
            </fragment>
            <fragment v-else-if="item.entity === 'hotels'">
              {{ $t('landing.secFour.prod6') }}
            </fragment>
            <fragment v-else-if="item.entity === 'vuelos' || item.entity === 'flights'">
              {{ $t('landing.secFour.prod1') }}
            </fragment>
            <br />
            <b>{{ $t('lbl.createCotization3') }}</b>: {{ $moment(item.date_servicio).format('llll') }}<br />
            <b>{{ $t('lbl.createCotization4') }}</b>: {{ user.name }} {{ user.apellidos }}<br />
            <b>Email</b>: <a :href="`mailito:${user.email}`">{{ user.email }}</a>
          </p>
        </v-col>

        <!--v-show="false"-->
        <v-col
          v-show="false"
          id="printMe"
          cols="12"
          md="8"
          offset-md="2"
        >
          <v-simple-table
            v-if="name_project === 'ppppp'"
            :style="
              `border-style: solid;
              border-color: ${$vuetify.theme.themes.light.primary};
              border-radius: 50px;
              padding: 15px;
              `
            "
          >
            <template v-slot:default>
              <tbody>
                <voucherP :item="item" />
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table
            v-if="name_project === 'wwwww'"
            style="margin-left: 50px;"
          >
            <template v-slot:default>
              <tbody>
                <voucherW :item="item" />
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table
            v-if="name_project === 'ttttt'"
            style="margin-left: 50px;"
          >
            <template v-slot:default>
              <tbody>
                <voucherT :item="item" />
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table
            v-if="name_project === 'kkkkk'"
            style="margin-left: 50px;"
          >
            <template v-slot:default>
              <tbody>
                <voucherK :item="item" />
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="250"
          src="@/assets/images/3d-characters/under-maintenance.png"
        ></v-img>
      </div>

      <v-btn
        color="primary"
        class="mb-4 ml-2"
        @click="print"
      >
        {{ $t('btn.print') }}
      </v-btn>
      <v-btn
        color="primary"
        class="mb-4 ml-2 mr-2"
        @click="showEmails"
      >
        {{ $t('btn.sendEmail') }}
      </v-btn>
      <v-btn
        color="primary"
        :to="{ name: 'reservations' }"
        class="mb-4"
      >
        {{ $t('menu.reservas') }}
      </v-btn>
    </div>

    <v-dialog
      v-model="isDialogEmails"
      scrollable
      max-width="350px"
    >
      <v-card max-height="500px">
        <v-card-title>{{ $t('lbl.sendNotification') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="email"
                class="mt-5"
                :label="$t('lbl.email')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogEmails = !isDialogEmails"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :loading="loading"
            @click="sendNotification"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import voucherP from '@/views/vouchers/voucherP.vue'
// eslint-disable-next-line import/no-unresolved
import voucherW from '@/views/vouchers/voucherW.vue'
// eslint-disable-next-line import/no-unresolved
import voucherT from '@/views/vouchers/voucherT.vue'
import voucherK from '@/views/vouchers/voucherK.vue'

export default {
  components: {
    voucherP,
    voucherW,
    voucherT,
    voucherK,
  },
  data() {
    return {
      config: {
        logo: null,
        name: '',
      },
      item: {},
      user: {},
      isLoading: true,
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      loading: false,
      isDialogEmails: false,
      email: null,
    }
  },
  mounted() {
    this.load()
  },
  created() {
    this.getReservation()
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          this.user = response.data.data
        })
        .catch(error => console.log(error))
    },
    load() {
      this.axios
        .get('configuration-view/data-general')
        .then(res => {
          if (res.data.success) {
            this.config = res.data.data.data.data

            this.$vuetify.theme.themes.light.primary = this.config.color
          }
        })
        .finally(() => {
          this.profile()
        })
    },
    getReservation() {
      if (sessionStorage.getItem('reservations-id') !== null) {
        this.axios
          .get(`reservations/${sessionStorage.getItem('reservations-id')}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.item = res.data.data.data

            // console.log(this.item)
            if (this.item.emails) {
              if (this.item.emails.length > 0) {
                this.email = this.item.emails[0].email
              }
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    showEmails() {
      this.isDialogEmails = true
    },
    sendNotification() {
      if (this.email) {
        this.loading = true
        const json = {
          email: this.email,
        }
        this.axios
          .post(`reservations/update-emails/${sessionStorage.getItem('reservations-id')}`, json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // console.log(response)
            // eslint-disable-next-line no-empty
            if (response.data.success === false) {
            } else {
              this.$toast.success(this.$t('msg.sendNotification'))

              // this.$router.push({ name: 'cars-contrate-list' })
            }
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.loading = false
            this.isDialogEmails = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    print() {
      // Pass the element id here
      this.$htmlToPaper('printMe')
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
<style>
.voucherPrint {
  border-style: solid;
  border-color: rgba(201, 76, 76, 0.3);
}
@media print {
  @page {
    size: landscape;
  }
}
</style>
