<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/valid-v-model -->
<template>
  <fragment v-if="!isLoading">
    <tr>
      <td
        style="margin-left: 25px;
        margin-top: 0px;
        min-width: 650px !important;
        text-align: left;
        vertical-align: top;"
      >
        <img
          style="max-width: 250px;max-height: 250px;"
          :src="`${url_public}config/logopdf.png`"
        />
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr style="text-align: left;">
                <td colspan="2">
                  5ta Avenida 6403, e/ 64 y 66 Playa – La Habana – Cuba
                </td>
              </tr>
              <tr style="text-align: left;">
                <td>
                  Tel.:
                </td>
                <td>+53 7 2049309</td>
              </tr>
              <tr style="text-align: left;">
                <td></td>
                <td>+53 55813453</td>
              </tr>

              <tr style="text-align: left;">
                <td>
                  Email:
                </td>
                <td><a>reservas@viajeskronoscuba.com</a></td>
              </tr>
              <!--<tr style="text-align: left;">
                <td></td>
                <td><a>reservas@viajeskronos.com</a></td>
              </tr>-->

              <tr style="text-align: left;">
                <td>
                  Web:
                </td>
                <td><a>www.viajeskronoscuba.com</a></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
      <td
        width="50%"
        valign="top"
        style="min-width: 650px !important;
        text-align: left;
        vertical-align: top;"
      >
        <v-simple-table
          :style="
            `border-style: solid;
              border-color: #f2f2f2;
              border-radius: 50px;
              text-align: left;
              vertical-align: top;
              `
          "
        >
          <template v-slot:default>
            <tbody>
              <tr>
                <td style="padding: 30px 30px 200px 30px;text-align: left;vertical-align: top;">
                  <b>{{ proveedor }}</b>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </tr>
    <tr style="text-align: right;">
      <td colspan="2">
        Printed {{ $moment(item.date_compra).format('D MMM, YYYY') }}
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <b>VOUCHER n. {{ item.number_vouchers[0].voucher }}</b>
      </td>
    </tr>
    <tr v-if="item.entity === 'cars'">
      <td colspan="2">
        <table style="margin-top: 20px;width: 100%;">
          <body style="min-width: 650px !important;">
            <tr
              :style="
                `background-color: ${$vuetify.theme.themes.light.primary} !important;
                          color: #fff !important;
                          padding: 5px;`
              "
            >
              <td>
                From
              </td>
              <td>
                To
              </td>
              <td>
                Service
              </td>
              <td>
                Pick Up
              </td>
              <td>
                Drop Off
              </td>
            </tr>
            <tr>
              <td>
                {{ $moment(item.data_service.dateRecogida).format('D/MMM/YYYY') }}
              </td>
              <td>
                {{ $moment(item.data_service.dateEntrega).format('D/MMM/YYYY') }}
              </td>
              <td>
                {{ item.data_service.items.car.name }}
                <span> o similar </span>
                <span
                  v-if="item.data_service.items.category_id"
                >({{ item.data_service.items.category_id.name }})
                </span>
              </td>
              <td>
                {{ item.data_service.recogida.name }} {{ $moment(item.data_service.dateRecogida).format('H:mm') }}
              </td>
              <td>{{ item.data_service.entrega.name }} {{ $moment(item.data_service.dateEntrega).format('H:mm') }}</td>
            </tr>
          </body>
        </table>
      </td>
    </tr>
    <tr v-if="item.entity === 'cars'">
      <td style="padding: 10px;"></td>
    </tr>
    <tr v-if="item.entity === 'cars'">
      <td colspan="2">
        <b>{{ $t('lbl.daysRent') }}:</b>
        <span style="padding-left: 10px;">{{ item.data_service.items.diasReservar }}</span>
      </td>
    </tr>

    <tr v-if="item.entity === 'cars' && item.data_client.conductorAditional">
      <td style="padding: 10px;"></td>
    </tr>
    <tr v-if="item.entity === 'cars' && item.data_client.conductorAditional">
      <td colspan="2">
        <b>NOTES</b>
        <br />
        CONDUCTOR ADICIONAL<br />
        {{ item.data_client.nameConductorAditional }}
        {{ item.data_client.nameSecoundConductorAditional }}
        {{ item.data_client.apellidosConductorAditional }}
        <span
          v-if="item.data_client.noDocumentConductorAditional"
        >({{ item.data_client.noDocumentConductorAditional }})</span>
      </td>
    </tr>
    <tr>
      <td style="padding: 20px;"></td>
    </tr>
    <tr v-if="item.entity === 'cars' && item.data_service.items.suplementos.length > 0">
      <td colspan="2">
        Incluye:<br />
        <br />
        <span
          v-for="(suplement, indS) in item.data_service.items.suplementos"
          :key="indS"
        >
          {{ suplement.suplemento.name
          }}<span v-if="indS < item.data_service.items.suplementos.length - 1">
            <br />
          </span>
        </span>
      </td>
    </tr>
    <tr v-if="item.entity === 'cars'">
      <td>
        <table style="margin-top: 20px;width: 100%;">
          <body style="min-width: 650px !important;">
            <tr
              :style="
                `background-color: ${$vuetify.theme.themes.light.primary} !important;
                          color: #fff !important;
                          padding: 5px;`
              "
            >
              <td>
                Pax
              </td>
              <td width="50px">
                Adult
              </td>
              <td width="50px">
                Chd
              </td>
            </tr>
            <tr>
              <td>
                {{ item.data_client.nameConductorPrincipal }}
                {{ item.data_client.nameSecoundConductorPrincipal }}
                {{ item.data_client.apellidosConductorPrincipal }}
              </td>
              <td>
                {{ item.data_client.conductorAditional ? 2 : 1 }}
              </td>
              <td></td>
            </tr>
          </body>
        </table>
      </td>
    </tr>

    <tr v-if="item.entity === 'cars' && item.number_confirmed">
      <td>
        <table style="margin-top: 20px;width: 100%;border: 0.3px solid;">
          <body style="min-width: 650px !important;">
            <tr>
              <td style="padding: 20px 0 20px 10px;">
                No. confirmación del proveedor del servicio:
                <b>{{ item.number_confirmed }}</b>
              </td>
            </tr>
          </body>
        </table>
      </td>
    </tr>

    <fragment v-if="conditions.length > 0">
      <tr
        v-for="(condition, indC) in conditions"
        :key="indC"
      >
        <td
          style="text-align: left;"
          colspan="2"
        >
          <br v-if="indC === 0" />
          <b>{{ condition.name }}</b>
          <span
            v-for="(cod, indCod) in condition.conditions"
            :key="indCod"
          >
            <p
              style="font-size: 9px;"
              v-html="cod.description"
            ></p>
          </span>
        </td>
      </tr>
    </fragment>

    <!--<tr>
      <td style="text-align: left;">

        <span
          v-if="item.data_client.noDocumentConductorPrincipal"
        >({{ item.data_client.noDocumentConductorPrincipal }})</span>
      </td>
    </tr>
    <tr v-if="item.data_client.conductorAditional">
      <td style="text-align: right;">
        <b>{{ $t('lbl.conductorAditional') }}:</b>
      </td>
      <td style="text-align: left;">

    </tr>
    <tr>
      <td
        style="text-align: left;"
        colspan="2"
      >
        <p style="margin-top: 15px">
          <b>{{ $t('lbl.car') }}:</b>
        </p>
      </td>
    </tr>
    <tr>
      <td style="text-align: right;">
        <br />
        <b style="margin-top: 50px;">{{ $t('lbl.daysRent') }}:</b>
      </td>
      <td style="text-align: left;">
        <br />
        {{ item.data_service.items.diasReservar }}
      </td>
    </tr>
    <tr v-if="item.data_service.items.suplementos.length > 0">
      <td style="text-align: right;">
        <br />
        <b style="margin-top: 50px;">{{ $t('lbl.incluye') }}:</b>
      </td>
      <td style="text-align: left;"></td>
    </tr>

    <tr>
      <td
        colspan="2"
        style="text-align: center;"
      >
        <p style="margin-top: 125px;margin-left: 100px;"></p>
      </td>
    </tr>

    <tr>
      <td
        colspan="2"
        style="text-align: left;"
      >
        <v-simple-table style="margin-top: 50px;margin-left: 50px;">
          <template v-slot:default>
            <tbody style="min-width: 350px !important;font-size: 12px;">
              <tr>
                <td style="text-align: left;">
                  <b>Nota:</b>
                </td>
                <td style="text-align: left;">
                  <span>El número de confirmación del voucher es emitido por el proveedor local.</span>
                </td>
              </tr>
              <tr>
                <td style="text-align: left;"></td>
                <td style="text-align: left;">
                  <span>Al momento de su presentación será verificada su reserva.</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </tr>-->
  </fragment>
</template>
<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    item: {
      type: Object,
    },
  },
  data() {
    return {
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      proveedor: null,
      rentadora: null,
      idConditionsContrate: [],
      conditions: [],
      condicionesGenerales: [],
      categoriesCondiciones: [],
    }
  },
  created() {
    this.getContrate()
  },
  methods: {
    getCategCondiciones() {
      const json = {
        for_car: 1,
        per_page: 10000,
      }
      this.axios
        .post('generals/categories', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categoriesCondiciones = res.data.data
          }
        })
        .finally(() => {
          this.getCondiciones()
        })
    },
    getCondiciones() {
      const json = {
        for_car: 1,
        per_page: 10000,
      }
      this.axios
        .post('generals/conditions', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.condicionesGenerales = res.data.data
          }
        })
        .finally(() => {
          this.checkConditions()
        })
    },
    getContrate() {
      if (this.item.entity === 'cars') {
        this.axios
          .get(`contrate_cars/${this.item.data_service.items.contrate_id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.proveedor = res.data.data.data.proveedor.name_comercial
            this.rentadora = res.data.data.data.rentadora.name
            if (res.data.data.data.conditions) {
              this.idConditionsContrate = res.data.data.data.conditions
            }
          })
          .finally(() => {
            this.getCategCondiciones()
          })
      } else {
        this.isLoading = false
      }
    },
    checkConditions() {
      // console.log(this.idConditionsContrate)
      // console.log(this.condicionesGenerales)

      const arr = []
      this.condicionesGenerales.forEach(element => {
        if (this.idConditionsContrate.includes(element.id)) {
          arr.push(element)
        }
      })

      this.categoriesCondiciones.forEach(element => {
        let tieneConditions = false

        const cond = []
        arr.forEach(elementArr => {
          if (elementArr.category_id === element.id) {
            tieneConditions = true

            cond.push({
              name: elementArr.name,
              description: elementArr.description,
            })
          }
        })

        if (tieneConditions) {
          this.conditions.push({
            name: element.name,
            conditions: cond,
          })
        }
      })

      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
  },
}
</script>
